<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import headerData from './header'
import TableList from '@/mixins/table-list'
import ReturnsApi from '@/api/returns'

import RccTable from 'Components/table/rcc-table'

export default {
  mixins: [Page, TableList, ReturnsApi],

  components: {
    RccTable
  },

  computed: {
    headerFiltersData() {
      return [
        {
          filter: 'supplier',
          name: 'supplier_id',
          callback: this.$apiMethods.suppliers.allList
        },
        {
          filter: 'return_point',
          name: 'return_point_id',
          callback: this.$apiMethods.returnPoints.dcList
        },
        {
          filter: 'status',
          name: 'status_id',
          callback: this.$apiMethods.x5.statusesList
        },
        {
          filter: 'status_x5',
          name: 'status_fact_id',
          callback: this.$apiMethods.x5.statusesList
        }
      ]
    },

    headerData() {
      return headerData
    }
  },

  created() {
    this.initFilters()
  },

  methods: {
    initFilters() {
      this.headerFiltersData.forEach(filter => {
        this.setFilter(filter)
      })
    },

    setFilter({ filter, name, callback, type = 'multiple-autocomplete' }) {
      const index = this.headerData.findIndex(item => item.value === filter)

      if (index < 0) {
        return
      }

      this.headerData[index].customFilter.name = name
      this.headerData[index].customFilter.callback = callback
    },

    loadData(filters) {
      this.isDataLoading = true

      this.getChanges({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(({ pages, items }) => {
          if (!pages) {
            this.resetTableData()
            return
          }

          this.pagesCount = pages

          this.rows = items.map(item => ({
            ...item,
            booking_date: item.booking_date && this.$moment(item.booking_date).format('DD.MM.YYYY'),
            booking_date_x5: item.booking_date_x5 && this.$moment(item.booking_date_x5).format('DD.MM.YYYY')
          }))
        })
        .finally(() => { this.isDataLoading = false })
    }
  }
}
</script>
