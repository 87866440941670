export default [
  {
    text: 'Номер брони',
    value: 'application_code',
    customFilter: {
      type: 'search',
      label: 'Введите номер:',
      inputType: 'number'
    }
  },

  {
    text: 'Поставщик',
    value: 'supplier',
    width: '200px',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по поставщику:'
    }
  },

  {
    text: 'Дата',
    value: 'booking_date',
    customFilter: {
      name: 'booking_date',
      type: 'datepicker'
    }
  },

  {
    text: 'Измененная дата',
    value: 'booking_date_x5',
    customFilter: {
      name: 'booking_date_x5',
      type: 'datepicker'
    }
  },

  {
    text: 'Кол-во паллет',
    value: 'pallets_num',
    customFilter: {
      type: 'search',
      name: 'pallets_num',
      label: 'Введите кол-во паллет:',
      inputType: 'number'
    }
  },

  {
    text: 'Измененное кол-во паллет',
    value: 'pallets_num_x5',
    customFilter: {
      type: 'search',
      name: 'pallets_num_x5',
      label: 'Введите измененное кол-во паллет:',
      inputType: 'number'
    }
  },

  {
    text: 'Точка возврата',
    value: 'return_point',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по точке возврата'
    }
  },

  {
    text: 'Статус',
    value: 'status',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Писк по статусу'
    }
  },

  {
    text: 'Измененный статус',
    value: 'status_x5',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Писк по измененному статусу'
    }
  },

  {
    text: 'Номер ТС',
    value: 'ts_number',
    customFilter: {
      name: 'ts_number',
      type: 'search',
      label: 'Введите номер ТС:'
    }
  },

  {
    text: 'Измененный номер ТС',
    value: 'ts_number_x5',
    customFilter: {
      name: 'ts_number_x5',
      type: 'search',
      label: 'Введите измененный номер ТС:'
    }
  }
]
